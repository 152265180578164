<template>
  <table-view
    :total="total"
    :query-info.sync="queryInfo"
    @reload-table="renderTable"
  >
    <template #header>
      <view-search-form
        ref="searchFormRef"
        :queryInfo.sync="queryInfo"
        :tool-list="['grade','keyword']"
        keyword-placeholder="可输入姓名、学院、专业"
        keywordWidth="300px"
        @on-search="renderTable(1)">
        <el-form-item label="修改状态" prop="changeFlag">
          <el-select v-model="queryInfo.changeFlag" size="small" clearable>
            <el-option label="已修改" :value="1" />
            <el-option label="未修改" :value="0" />
          </el-select>
        </el-form-item>
      </view-search-form>
      <div class="header-button">
        <ElTooltip
          effect="light"
          placement="top"
        >
          <div slot="content" style="width:500px">
            <h3>广东南方职业学院录取查询H5登录页温馨提示：</h3>
            <div class="tips-content" v-html="admissionLetterTips.paramValue||'待填写'"></div>
          </div>
          <ElButton tabindex="-1" size="small" @click="handleParamDialog('admissionLetterTips')">录取查询H5文字提示
          </ElButton>
        </ElTooltip>
        <ElButton title="点击修改" size="small" @click="handleParamDialog('admissionLetterQuitEditTime')">
          收件信息修改截止时间：{{ quitTime.paramValue || '待设置' }}
        </ElButton>
        <el-button type="success" size="small" @click="exportExcel">导出该筛选条件录取通知</el-button>
        <upload-excel :permit-key="['stu:import:edit']"
                      :loading.sync="loading"
                      :api="$http.importExcelHttp.importAdmissionLetter"
                      @on-download="$http.exportExcelHttp.exportAdmissionLetterTemplate()"
                      @on-success="renderTable(1)" />
        <el-button type="primary" size="small" @click="showDialog()">添加录取通知
        </el-button>
      </div>
    </template>
    <!--  body  -->
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      stripe>
      <el-table-column prop="examineCode" label="考生号" width="130" align="center" fixed="left" />
      <!--      <el-table-column prop="examineNo" label="录取编号" width="130" align="center" />-->
      <el-table-column prop="grade" label="年级" width="80" align="center" />
      <el-table-column prop="stuName" label="录取学生" width="160" fixed="left" />
      <el-table-column prop="idNumber" label="身份证号" width="180" align="center" />
      <el-table-column prop="admissionStage" label="录取阶段" width="160" />
      <el-table-column prop="admissionExpressNumber" label="快递单号" width="220" align="center" />
      <el-table-column prop="addressee" label="收件人" width="120" />
      <el-table-column prop="addresseePhone" label="收件人手机号" width="140" align="center" />
      <el-table-column prop="admissionMailAddress" label="录取通知邮寄地址" min-width="200" />
      <el-table-column prop="addresseePostCode" label="收件邮政编码" width="110" />
      <el-table-column prop="addresseeChangeCount" width="130" align="center" fixed="right">
        <template #header>
          <tool-tips-group
            tips-content="通过H5/APP，只能修改一次，修改过后这里的状态会变为'已修改'。<br/> 后台不限制修改次数。">
            学生是否修改
          </tool-tips-group>
        </template>
        <template v-slot="{row}">
          <el-tag v-if="!!row.addresseeChangeCount">已修改</el-tag>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column prop="collegeName" label="院系" width="250" />
      <el-table-column prop="majorName" label="专业" width="200" />
      <el-table-column label="操作" width="230" fixed="right">
        <template v-slot="{row}">
          <template v-permission="['admission:letter:edit']">
            <el-button
              type="primary" size="mini"
              @click="showDialog(row.id,'录取通知')">编辑
            </el-button>
            <el-button type="primary" size="mini"
                       @click="clearEditType(row)">重置修改状态
            </el-button>
          </template>
          <el-button v-permission="['admission:letter:del']" size="mini" type="danger"
                     @click="del(deleteAdmissionLetter,row.id,`录取通知-${row.stuName}（${row.examineCode}）`)">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <AdmissionLetterDialog ref="dialogRef" :visible.sync="visibleDialog" v-if="visibleDialog" @on-close="renderTable" />
    <!--  编辑邮递信息修改截止时间  -->
    <ParamsDialog ref="paramDialogRef" :visible.sync="visibleParamDialog" v-if="visibleParamDialog"
                  @on-close="getQuitTime" />
  </table-view>
</template>

<script>
import tableView from '@/vue/mixins/table-view'
import AdmissionLetterDialog from '@/views/pages/recruitManage/admission-letter/components/AdmissionLetterDialog.vue'
import { getSysParamsByKey } from '@/api/sysParams'
import ParamsDialog from '@/views/pages/recruitManage/admission-letter/components/ParamsDialog.vue'
import { pageAdmissionLetterApi, deleteAdmissionLetter, saveAdmissionLetterApi } from '@/api/admission-letter-api'

export default {
  name: 'AdmissionLetter',
  components: {
    ParamsDialog,
    AdmissionLetterDialog
  },
  mixins: [tableView],
  data() {
    return {
      visibleParamDialog: false,
      queryInfo: {
        changeFlag: null,
        keyword: '',
        grade: new Date().getFullYear()
      },
      quitTime: {}, // 录取通知信息修改截止时间 用于h5
      admissionLetterTips: {} // 录取通知信息h5 登录页的温馨提示
    }
  },
  created() {
    this.getQuitTime()
    this.renderTable(1)
  },
  methods: {
    deleteAdmissionLetter,
    // 获取学生列表
    async renderTable(pageNum) {
      await this.getTableData(pageAdmissionLetterApi, pageNum)
    },
    // 获取录取查询h5的两个参数：截止时间和登录页提示
    async getQuitTime() {
      try {
        const { data } = await getSysParamsByKey('admissionLetterQuitEditTime')
        this.quitTime = data
        const { data: data2 } = await getSysParamsByKey('admissionLetterTips')
        this.admissionLetterTips = data2
      } catch (e) {
        console.error(e)
      }
    },
    // 修改系统参数
    async handleParamDialog(key) {
      this.visibleParamDialog = true
      await this.$nextTick()
      this.$refs.paramDialogRef.key = key
      await this.$refs.paramDialogRef.getData(key)
    },
    // 根据筛选条件导出录取通知信息
    async exportExcel() {
      await this.$http.exportExcelHttp.exportAdmissionLetter(this.queryInfo)
    },
    // 重置修改状态
    clearEditType(row) {
      this.$confirm(`是否要重置【${row.stuName}】的邮寄地址修改状态？`, '提示').then(async () => {
        try {
          await saveAdmissionLetterApi({ ...row, addresseeChangeCount: 0 })
          await this.renderTable()
          this.$message.success('邮寄地址修改状态已重置')
        } catch (e) {
          console.error(e)
        }
      }).catch(() => {
        this.$message.info('取消操作')
      })
    }
  }
}
</script>
