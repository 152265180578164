<template>
  <el-dialog
    class="admission-letter-dialog"
    :title="`${preTitle}录取通知书`"
    :visible.sync="show"
    :close-on-click-modal="false"
    width="860px"
    :before-close="cancel">
    <el-form ref="form" :model="formData" :rules="rules" label-width="150px">
      <el-divider content-position="left"><h3>录取个人信息:</h3></el-divider>
      <el-row>
        <el-col :span="12">
          <el-form-item label="录取学生" prop="stuName">
            <el-input v-model.trim="formData.stuName" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="考生号" prop="examineCode">
            <el-input v-model.trim="formData.examineCode" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="录取院系" prop="collegeName">
            <el-input v-model.trim="formData.collegeName" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="录取专业" prop="majorName">
            <el-input v-model.trim="formData.majorName" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="录取阶段" prop="admissionStage">
            <el-input v-model.trim="formData.admissionStage" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="身份证号" prop="idNumber">
            <el-input v-model.trim="formData.idNumber" maxlength="18" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="年级" prop="grade">
            <el-select v-model="formData.grade" filterable>
              <el-option v-for="{ paramValue, id } in gradeList" :key="id" :label="paramValue"
                         :value="Number(paramValue)" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider content-position="left"><h3>快递收件信息</h3></el-divider>
      <el-row>
        <el-col :span="12">
          <el-form-item label="收件人" prop="addressee">
            <el-input v-model.trim="formData.addressee" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="收件手机号" prop="addresseePhone">
            <el-input v-model.trim="formData.addresseePhone" maxlength="11" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="收件邮政编码" prop="addresseePostCode">
            <el-input v-model.trim="formData.addresseePostCode" maxlength="6" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="快递单号" prop="admissionExpressNumber">
            <el-input v-model.trim="formData.admissionExpressNumber" maxlength="20" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="邮寄地址(省市区)" prop="provinces">
            <el-cascader v-if="formData.provinces" v-model="formData.provinces" :options="provinceData"
                         :props="provinceProps" clearable
                         style="width:100%;" @change="handleMailAddress" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="详细地址" prop="detailAddress">
            <el-input v-model.trim="formData.detailAddress" type="textarea" maxlength="100" rows="3"
                      @change="handleMailAddress" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
      <el-button type="primary" @click="save('录取通知书信息', saveAdmissionLetterApi)">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { getAdmissionLetterById, saveAdmissionLetterApi } from '@/api/admission-letter-api'
import dialog from '@/vue/mixins/dialog'
import { validateNumber, validatePhone, validateNumEng } from '@/utils/form-validate'
import campusSchMajorClasses from '@/vue/mixins/campusSchMajorClasses'
import provinceData from '@/views/pages/recruitManage/admission-letter/components/province.js'

export default {
  name: 'AdmissionLetterDialog',
  mixins: [dialog, campusSchMajorClasses],
  data() {
    return {
      provinceData,
      provinceProps: {
        expandTrigger: 'hover',
        value: 'name',
        label: 'name',
        children: 'children'
      },
      gradeList: this.$store.state.systemParam.grade, // 年级list
      admissionMailAddress: '',
      formData: {
        detailAddress: '', // 前端字段
        provinces: [], // 前端字段
        id: '',
        stuName: '',
        examineCode: '',
        admissionStage: '',
        grade: '',
        idNumber: '',
        collegeName: '',
        majorName: '',
        addressee: '',
        addresseePhone: '',
        addresseePostCode: '',
        admissionMailAddress: '',
        admissionExpressNumber: '',
        remarks: ''
      },
      rules: {
        grade: { required: true, message: '该项为必填', trigger: 'blur' },
        examineCode: [
          {
            required: true,
            message: '该项为必填',
            trigger: 'blur'
          },
          this.$store.state.commonValidate.validatorNumberObj
        ],
        admissionStage: [
          {
            required: true,
            message: '该项为必填',
            trigger: 'blur'
          },
          this.$store.state.commonValidate.limit30WordsObj
        ],
        majorName: [
          {
            required: true,
            message: '该项为必填',
            trigger: 'blur'
          },
          this.$store.state.commonValidate.limit30WordsObj
        ],
        collegeName: [
          {
            required: true,
            message: '该项为必填',
            trigger: 'blur'
          },
          this.$store.state.commonValidate.limit30WordsObj
        ],
        detailAddress: [
          {
            required: true,
            message: '该项为必填',
            trigger: 'blur'
          }
        ],
        provinces: [
          {
            required: true,
            message: '该项为必填',
            trigger: 'blur'
          }
        ],
        stuName: [
          {
            required: true,
            message: '该项为必填',
            trigger: 'blur'
          },
          this.$store.state.commonValidate.limit30WordsObj
        ],
        addressee: [
          {
            required: true,
            message: '该项为必填',
            trigger: 'blur'
          },
          this.$store.state.commonValidate.limit30WordsObj
        ],
        admissionExpressNumber: { validator: validateNumEng, trigger: 'blur' },
        admissionMailAddress: [{
          required: true,
          message: '该项为必填',
          trigger: 'blur'
        }],
        addresseePhone: [
          {
            required: true,
            message: '请填写联系人手机号码',
            trigger: 'blur'
          },
          {
            validator: validatePhone,
            trigger: 'blur'
          }
        ],
        addresseePostCode: [{
          validator: validateNumber,
          trigger: 'blur'
        }]
      }
    }
  },
  async mounted() {
    await this.$nextTick()
    if (this.id) {
      await this.getDataById(this.id, getAdmissionLetterById)
      if (!this.formData.provinces) {
        this.formData.provinces = []
      }
      // 根据返回的地址 逐层筛选，回显省市区
      if (this.formData.admissionMailAddress) {
        const _admissionMailAddress = this.formData.admissionMailAddress
        const _provinceList = []
        for (let i = 0; i < this.provinceData.length; i++) {
          const _provinceTarget = this.provinceData[i]
          if (_admissionMailAddress.indexOf(_provinceTarget.province) > -1) {
            _provinceList.push(_provinceTarget.province)
            for (let j = 0; j < _provinceTarget.children.length; j++) {
              const _cityTarget = _provinceTarget.children[j]
              if (_admissionMailAddress.indexOf(_cityTarget.city) > -1) {
                _provinceList.push(_cityTarget.city)
                for (let k = 0; k < _cityTarget.children.length; k++) {
                  const _regionTarget = _cityTarget.children[k]
                  if (_admissionMailAddress.indexOf(_regionTarget.region) > -1) {
                    _provinceList.push(_regionTarget.region)
                    break
                  }
                }
                break
              }
            }
            break
          }
        }
        this.formData.provinces = _provinceList
        const _regionString = _provinceList.join('')
        const _regionString2 = _provinceList.join('/') + '/'
        this.formData.detailAddress = _admissionMailAddress.replace(_regionString, '').replace(_regionString2, '')
      }
    } else {
      this.setInitData()
    }
  },
  methods: {
    // 保存api
    saveAdmissionLetterApi,
    // 邮寄地址 导入数据里存在斜杠，因此这里也要斜杠
    handleMailAddress() {
      this.formData.admissionMailAddress = (this.formData?.provinces?.join('/') || '') + '/' + this.formData.detailAddress
    }
  }
}
</script>
<style lang="scss">
.admission-letter-dialog {
  .el-dialog {
    margin-top: 20px !important;
  }
}
</style>
